// reset and common style
@import "./assets/style/base.scss";
@import "./assets/style/common.scss";
@font-face {
  font-family: AliHYAiHei;
  src: url("./assets/font/AliHYAiHei.ttf");
}
@font-face {
  font-family: Futura;
  src: url("./assets/font/Futura.ttf");
}
@font-face {
  font-family: CenturyGothic;
  src: url("./assets/font/CenturyGothic.ttf");
}
@font-face {
  font-family: CenturyGothicBold;
  src: url("./assets/font/CenturyGothicBold.ttf");
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
body {
  user-select: none;
}

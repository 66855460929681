.header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 15px 18px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all linear 0.2s;
  .headerLeft,
  .headerRight {
    flex: 1;
    img {
      height: 22px;
      margin-left: 14px;
    }
    .cartBox {
      position: relative;
      .cartRate {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        background-color: red;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
  }
  .headerLeft {
    display: flex;
    justify-content: flex-start;
    img {
      height: 26px;
    }
  }
  .headerRight {
    display: flex;
    justify-content: flex-end;
  }
  .headerLogoBox {
    // flex: 1;
    display: flex;
    justify-content: center;
    .headerLogo {
      width: 110px;
      display: block;
      height: 32.25px;
    }
  }
}
.headerScrolled {
  background-color: #fff;
}

.leftPop {
  .leftPopHeader {
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 18px;
  }
  .leftBottomList {
    height: calc(var(--vh) * 100 - 60px);
    padding: 0 18px 24px;
    overflow: auto;
    :global {
      .adm-list-body {
        border: none;
        .adm-list-item {
          padding: 0;
          border: none;
          .adm-list-item-content {
            padding: 0;
            border: none;
            .adm-list-item-content-main {
              font-family: Futura;
              color: #5c5c5c;
              line-height: 1.65;
              font-size: 15px;
              padding: 20px 0;
            }
          }
        }
        .adm-collapse-panel-content {
          border-bottom: 1px solid #dcdada !important;
          .adm-list-item-content-main {
            padding: 0 !important;
          }
        }
      }
    }
    .collapsible {
      font-family: Futura;
      color: #5c5c5c;
      line-height: 1.65;
      font-size: 15px;
      border-bottom: 1px solid #dcdada !important;
      padding: 20px 0;
    }
    .panelContent {
      padding-bottom: 18px;
      .panelContentItem {
        margin-left: 16px;
        color: #5c5c5c80;
        padding: 13px 0;
        font-size: 13px;
        font-family: Futura;
        line-height: 1.65;
      }
    }
  }
}
.topPop {
  padding: 14px 18px;
  .searchBox {
    display: flex;
    align-items: center;
    img {
      width: 18px;
    }
    .searchInput {
      padding: 0 6px;
      border-radius: 0;
      background-color: transparent;
      margin: 0 12px;
      :global {
        input {
          font-size: 15px;
          font-family: Futura;
        }
      }
    }
  }
  .continueButton {
    width: 100%;
    height: 43px;
    padding: 0;
    border: none;
    background-color: rgba(92, 92, 92, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 12px;
    letter-spacing: 2px;
  }
}
.rightPop {
  background: #f7f5f5;
  .rightPopHeader {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 18px;
    -webkit-box-shadow: 0 -1px #dcdada inset;
    box-shadow: 0 -1px #dcdada inset;
    .titleCart {
      font-size: 18px;
      font-family: Futura;
      color: rgb(50, 50, 50);
      line-height: 1.65;
    }
  }
  .rightBottom {
    height: calc(var(--vh) * 100 - 60px);
    background: #f7f5f5;
    display: flex;
    flex-direction: column;
    .rightEmpty {
      font-size: 16px;
      font-family: Futura;
      color: rgb(50, 50, 50);
      line-height: 1.65;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    .cartList {
      flex: 1 1 auto;
      overflow: auto;
      padding: 0 18px 0 24px;
    }
    .footerBox {
      -webkit-box-shadow: 0 -1px #dcdada;
      box-shadow: 0 -1px #dcdada;
      padding: 14px 24px 24px;
    }
    .noteContainer {
      .noteTitle {
        font-family: CenturyGothic;
        font-weight: 400;
        font-style: normal;
        color: #434343;
        line-height: 1.65;
        font-size: 15px;
      }
      .noteTextArea {
        margin-top: 10px;
        :global {
          textarea {
            padding: 12px 14px;
            border-radius: 0;
            border: 1px solid #dcdada;
            transition: all linear 0.15s;
            font-family: CenturyGothic;
            height: 62px;
          }
          textarea:focus {
            border-color: #838383;
          }
        }
      }
    }
    .recap {
      margin-top: 16px;
      .totalNum {
        text-align: right;
        line-height: 1.65;
        font-size: 15px;
        font-family: Futura;
        font-weight: 400;
        font-style: normal;
        color: #323232;
      }
      .subdued {
        color: #474645;
        font-family: CenturyGothic;
        font-weight: 400;
        font-style: normal;
        line-height: 1.65;
        font-size: 15px;
        text-align: left;
      }
    }
    .checkout {
      background-color: #e87e11;
      margin-top: 16px;
      border: 1px solid #e87e11;
      color: #fff;
      letter-spacing: 0.2em;
      font-family: Futura;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 13px;
      text-align: center;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        width: 3px;
        height: 3px;
        border-radius: 50px;
        background-color: #fff;
        margin: 0 18px;
      }
    }
  }
}

.content {
  background-color: #fff;
  padding: 34px 0;
  .container {
    margin: 0 auto;
    padding: 0 24px;
    .footerInner {
      .block {
        .blockTitle {
          color: #292828;
          margin-bottom: 20px;
          font-size: 15px;
          font-family: Futura;
          font-weight: 400;
        }
        .linklist {
          display: flex;
          flex-direction: column;
          margin-bottom: 36px;
          div {
            line-height: 1.5;
            color: #939393;
            font-family: Futura;
            font-size: 15px;
            margin-bottom: 12px;
            letter-spacing: 0.5px;
          }
        }
        .footerContent {
          margin-bottom: 1.6em;
          color: #939393;
          font-family: Futura;
          line-height: 1.65;
          font-size: 16px;
        }
        .emailInput {
          padding: 12px 14px;
          border-radius: 0;
          border: 1px solid #dcdada;
          width: 100%;
          background-color: transparent;
          margin-bottom: 24px;
          :global {
            input {
              font-size: 15px;
              font-family: Futura;
            }
          }
        }
        .subscribe {
          background-color: #e87e11;
          color: #fff;
          font-size: 13px;
          padding: 14px 28px;
          font-family: Futura;
          display: inline-block;
          text-transform: uppercase;
          letter-spacing: 0.2em;
          margin-bottom: 32px;
        }
        .email {
          color: #939393;
          font-family: Futura;
          line-height: 1.65;
          font-size: 16px;
          strong {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.content {
  display: flex;
  align-items: center;
  margin: 30px 0;
  .cartItemImage {
    width: 90px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .cartInfo {
    flex: 1;
    padding-left: 25px;
    width: calc(100% - 90px);
    .title {
      // width: calc(100vw - 170px);
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Futura;
      font-size: 12px;
      font-weight: 400;
      color: #323232;
      margin-bottom: 0.45em;
      line-height: 1.65;
    }
    .variant {
      font-family: Futura;
      font-size: 12px;
      font-weight: 400;
      color: #323232;
      margin-bottom: 0.45em;
      line-height: 1.65;
      font-size: 12px;
    }
    .priceList {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      font-family: Futura;
      .price {
        color: #f20000;
        font-size: 10px;
        line-height: 1.65;
      }
      .oldPrice {
        margin-left: 10px;
        color: #474645;
        position: relative;
      }
      .oldPrice::before {
        position: absolute;
        content: "";
        top: 50%;
        left: -0.4em;
        width: calc(100% + 0.8em);
        height: 1px;
        background: currentColor;
      }
    }
    .boxStepperDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .boxStepper {
        padding: 0px 0px;
        border: 1px solid #dcdada;
        display: inline-block;
        :global {
          .adm-input-element {
            font-family: Futura;
            font-size: 12px;
          }
        }
      }
      .remove {
        position: relative;
        display: inline-block;
        margin: 8px 0;
        font-size: 9px;
        font-family: Futura;
        color: #6e6c6b;
        line-height: 1.65;
      }
      .remove::before {
        background: #6e6c6b;
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: -1px;
        background: currentColor;
        -webkit-transform: scale(1, 1);
        transform: scale(1);
        -webkit-transform-origin: left center;
        transform-origin: left center;
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        transition: -webkit-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out,
          -webkit-transform 0.2s ease-in-out;
      }
    }
  }
}
